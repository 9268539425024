
import { defineComponent } from 'vue'
import { companyInfo } from '@/utils/webSideInfo'

export default defineComponent({
  setup () {
    const imgUrl = {
      value: require('@/assets/image/contact.png')
    }

    return {
      imgUrl,
      companyInfo
    }
  }
})
